var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SellingPrice" },
    [
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          attrs: { "success-text": "加载成功" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _vm.ishow
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          !_vm.ishow
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "cell1",
                      on: {
                        click: function($event) {
                          return _vm.ToSellPricingDetails(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "top" }, [
                        _c("div", { staticClass: "flex" }, [
                          item.status == "UNDELIVERED"
                            ? _c("div", { staticClass: "icon" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkList,
                                      expression: "checkList"
                                    }
                                  ],
                                  staticClass: "checkBox",
                                  attrs: {
                                    type: "checkbox",
                                    name: "checkBoxTest"
                                  },
                                  domProps: {
                                    value: item,
                                    checked: Array.isArray(_vm.checkList)
                                      ? _vm._i(_vm.checkList, item) > -1
                                      : _vm.checkList
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.checkList,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = item,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.checkList = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.checkList = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.checkList = $$c
                                        }
                                      },
                                      _vm.checkBox
                                    ],
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                })
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(item.goods[0].name) +
                                " (" +
                                _vm._s(
                                  item.businessType == "0"
                                    ? "近"
                                    : item.businessType == "3"
                                    ? "延"
                                    : "延"
                                ) +
                                ")"
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "state",
                            class: {
                              stateGreen:
                                item.status == "DONE" ||
                                item.status == "VIOLATE",
                              stateash:
                                item.status == "FAIL" || item.status == "CANCEL"
                            },
                            style: {
                              color:
                                item.status == "DONE" ||
                                item.status == "VIOLATE"
                                  ? "#02790D"
                                  : item.status == "FAIL" ||
                                    item.status == "CANCEL"
                                  ? "#999999"
                                  : "#FF2D2E"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.status == "SUSPENSE"
                                    ? "待审核"
                                    : item.status == "UNDELIVERED"
                                    ? "待交料"
                                    : item.status == "DONE" ||
                                      item.status == "VIOLATE"
                                    ? "已完成"
                                    : item.status == "RECEIVE"
                                    ? "待处理"
                                    : item.status == "CANCEL"
                                    ? "已取消"
                                    : "已拒绝"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "extra" }, [
                        _c("div", { staticClass: "color999" }, [
                          _vm._v(_vm._s(_vm._f("formatDate_")(item.createTime)))
                        ]),
                        _c("div", { staticClass: "color999" }, [
                          _vm._v(
                            "定价单价: " + _vm._s(item.goods[0].price) + "元/克"
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("div", { staticClass: "weight" }, [
                          _c("div", { staticClass: "number" }, [
                            _vm._v(_vm._s(item.weight.toFixed(2)))
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v("定价重量(克)")
                          ])
                        ]),
                        _c("div", { staticClass: "weight" }, [
                          _c("div", { staticClass: "number" }, [
                            _vm._v(
                              _vm._s(
                                item.status == "FAIL" || item.status == "CANCEL"
                                  ? "--"
                                  : item.status == "VIOLATE"
                                  ? item.weight.toFixed(2)
                                  : (item.weight - item.freeWeight).toFixed(2)
                              )
                            )
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v("已结重量(克)")
                          ])
                        ]),
                        _c("div", { staticClass: "weight" }, [
                          _c(
                            "div",
                            {
                              staticClass: "number",
                              style: {
                                color:
                                  item.status == "FAIL" ||
                                  item.status == "CANCEL"
                                    ? "#999999"
                                    : "#5473ff"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  item.status == "FAIL" ||
                                    item.status == "CANCEL"
                                    ? "--"
                                    : item.status == "VIOLATE"
                                    ? "0.00"
                                    : item.freeWeight.toFixed(2)
                                )
                              )
                            ]
                          ),
                          _c("div", { staticClass: "text" }, [
                            _vm._v("剩余重量(克)")
                          ])
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "van-dialog",
            {
              attrs: { "show-confirm-button": false, "get-container": "#app" },
              model: {
                value: _vm.statementShow,
                callback: function($$v) {
                  _vm.statementShow = $$v
                },
                expression: "statementShow"
              }
            },
            [
              _c("div", { staticClass: "dialog_title" }, [_vm._v("交料")]),
              _c("div", { staticClass: "centerbox_dialog" }, [
                _c("div", { staticClass: "flex_between col999" }, [
                  _c("div", [_vm._v("快递费 (元)")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.userInfo.expressFee))])
                ]),
                _c("div", { staticClass: "flex_between col999" }, [
                  _c("div", [_vm._v("保险费（元）")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.userInfo.insuranceFee))])
                ]),
                _c("div", { staticClass: "flex_between col999" }, [
                  _c("div", [_vm._v("首饰折价（元）")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.userInfo.purificationFee))])
                ]),
                _c("div", { staticClass: "flex_between col999" }, [
                  _c("div", [_vm._v("小金库剩余黄金（克）")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.userInfo.goldIncomingFreeWeight))
                  ])
                ]),
                _c("div", { staticClass: "flex_between break" }, [
                  _c("div", [_vm._v("交料克重（克）")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.referenceWeight))])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "dialog_title",
                  staticStyle: { "text-align": "left", "margin-bottom": "0" }
                },
                [_vm._v("明细")]
              ),
              _c("div", { staticClass: "referlist flex_between" }, [
                _c("div", { staticClass: "referlistDetail" }, [
                  _vm._v("定价价格")
                ]),
                _c("div", { staticClass: "referlistDetail" }, [
                  _vm._v("定价克重")
                ]),
                _c("div", { staticClass: "referlistDetail" }, [
                  _vm._v("剩余克重")
                ]),
                _c("div", { staticClass: "referlistDetail" }, [
                  _vm._v("引用克重")
                ])
              ]),
              _vm._l(_vm.orderList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "referlist flex_between" },
                  [
                    _c("div", { staticClass: "referlistDetail" }, [
                      _vm._v(_vm._s(item.goods[0].price))
                    ]),
                    _c("div", { staticClass: "referlistDetail" }, [
                      _vm._v(_vm._s(item.weight))
                    ]),
                    _c("div", { staticClass: "referlistDetail" }, [
                      _vm._v(_vm._s(item.freeWeight))
                    ]),
                    _c("div", { staticClass: "referlistDetail" }, [
                      _vm._v(_vm._s(item.referWeight))
                    ])
                  ]
                )
              }),
              _c("div", { staticClass: "flex_between popbtn" }, [
                _c(
                  "div",
                  { staticClass: "gocer closebtn", on: { click: _vm.close } },
                  [_vm._v("取消")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "gocer confirmbtn",
                    on: { click: _vm.commit }
                  },
                  [_vm._v("确定")]
                )
              ])
            ],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }